import { Model } from '@vuex-orm/core'
export default class WormNode extends Model {
  static entity = 'WormNode'

  static primaryKey = 'uuid'

  static state ()  {
    return {
      fetching: false,
      editingRuleUuid: null,
      openedNodes: [],
      classificators: []
    }
  }

  static fields () {
    return {
      uuid: this.attr(null),
      title: this.string(null).nullable(),
      pressmark: this.string(null).nullable(),
      nodeType: this.attr(null).nullable(),
      projectWormUuid: this.attr(null).nullable(),
      parentUuid: this.attr(null).nullable(),
      classificatorFindRuleUuid: this.attr(null).nullable(),
      showVolume: this.boolean(null).nullable(),
      dataExportType: this.attr(null).nullable(),
      conditionLinks: this.attr(null).nullable(),
      cnt: this.attr(null).nullable(),
      value: this.attr(null).nullable(),
      srt: this.attr(null).nullable(),
      elementName: this.string(null).nullable(),
      resource: this.boolean(false),
      hasChildren: this.boolean(false),
      // children: this.hasMany(WormNode, 'parentUuid'),

      logicValidy: this.attr(null).nullable(),
      classificatorFindRule: this.attr(null).nullable(),
      classificatorNode: this.attr(null).nullable(),

      isEdit: this.boolean(false),
      isCalculating: this.boolean(false),
      hasValidRules: this.boolean(false),

      comment: this.string(null).nullable(),
      drawingpages: this.attr(null).nullable()
    }
  }

  static apiConfig = {
    actions: {
      fetchByProjectWorm(uuid) {
        let classifiers = []
        WormNode.commit((state) => classifiers = state.classificators)
        WormNode.commit((state) => {state.fetching = true})
        let data = this.get(`/worm/projectworm/${uuid}/nodes`,{
          dataTransformer: ({ data }) => {
            for (let n of data) {
              let projectClassifiers = classifiers.find(el => el.uuid == n?.classificatorNode?.projectClassificatorUuid)
              if (!projectClassifiers) {
                n.classificatorFindRule = null 
                n.classificatorFindRuleUuid = null
                n.classificatorNode = null
              }
              
              if (data.some(node => n.uuid == node.parentUuid)){
                n.hasChildren = true
              }

              if (!n.hasChildren && n.logicValidy && n.logicValidy?.valid) {
                n.hasValidRules = true
              }
            }
            return data
          }
        })
        data.then(() => {
          WormNode.commit((state) => {state.fetching = false})}
        )
        return data
      },

      recalculate(uuid) {
        WormNode.update({where: uuid, data: {isCalculating: true}})
        let data = this.get(`/worm/recalculateworm/${uuid}`, {
          dataTransformer: ({ data }) => {
            data.isCalculating = false;
            return data
          }
        })
        
        return data
      },

      getProjectClassificators(uuid) {
        return this.get(`/smeta/projclass/${uuid}/list`).then(data => {
          WormNode.commit((state) => { state.classificators = data.response.data })
        })
      },
    }
  }

  // get children(){
  //   return WormNode.query().where('parentUuid', this.uuid).orderBy(el => el.nodeType.value).orderBy('srt').orderBy('pressmark').get()
  // }
  
}