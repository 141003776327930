import debounce from 'lodash/debounce'
import xorBy from "lodash/xorBy"

import { api } from "@/api"
import details from './rule-details.module'
import global from './rule-global.module'
import { AlertService } from '@app/AlertService'
import i18n from '@/plugins/i18n'

import { useAttributeCheckingTableStore } from '@/pinia'

function hasRules (folder) {
  if (folder.rules.length) return true
  for (const one of folder.children) {
    if (hasRules(one)) return true
  }
  return false
}

function hasActiveRules (folder) {
  if (folder.rules.find(rule => rule.selectedToWork)) return true
  for (const one of folder.children) {
    if (hasActiveRules(one)) return true
  }
}

function RuleTreeMapper (element) {
  let one = { ...element }
  one.isFolder = true
  one.disabled = ! hasRules(one)
  one.nodes = [
    ...one.rules.map(i => ({ ...i, isRule: true })),
    ...one.children.map(RuleTreeMapper)
  ]
  return one
}

function ActiveRuleTreeMapper(element, isAttr) {
  let one = { ...element }
  one.isFolder = true
  one.disabled = ! hasActiveRules(one)
  one.nodes = [
    ...one.rules.filter(i => {
      let finderCheck = isAttr ? attributeFinder.includes(i.finder.className) : !attributeFinder.includes(i.finder.className)
      if (i.selectedToWork && finderCheck)
        return i
    }).map(i => ({ ...i, isRule: true })),
    ...one.children.map(ch => ActiveRuleTreeMapper(ch, isAttr))
  ]
  one.countCollisions = one.nodes.reduce((c, i) => c + i.countCollisions, 0)
  return one
}

let flatlist = function (tree, level = 1) {
  let array = []
  tree.forEach(element => {
    let newElement = { ...element }
    newElement.level = level
    array.push(newElement, ...flatlist(element.children, level + 1))
  })
  return array
}

let flatlistNodes = function (tree) {
  let array = []
  if (tree && tree?.length > 0) {
    tree.forEach(element => {
      if (element.isFolder) {
        let newElement = { ...element }
        array.push(newElement, ...flatlistNodes(element.nodes))
      }
    })
  }
  return array
}

function allSelectedRules (list, folder) {
  list.push(...folder.rules.filter(i => i.selected && !attributeFinder.includes(i.finder.className)))
  folder.children.reduce(allSelectedRules, list)
  return list
}

function allAttrSelectedRules (list, folder) {
  list.push(...folder.rules.filter(i => i.selected && attributeFinder.includes(i.finder.className)))
  folder.children.reduce(allAttrSelectedRules, list)
  return list
}

function allWorkedRules (list, folder) {
  list.push(...folder.rules.filter(i => i.selectedToWork))
  folder.children.reduce(allWorkedRules, list)
  return list
}

function allRules (tree) {
  let rules = []
  tree.forEach(element => {
    rules.push(...element.rules)
    if (element.children)
      rules.push(...allRules(element.children))
  })
  return rules
}

function markToWork(tree, list) {
  tree.forEach(element => {
    element.rules.forEach(rule => {
      rule.selectedToWork = list.indexOf(rule.uuid) != -1 ? true : false
    })
    if (element.children.length > 0) markToWork(element.children, list)
  })
}

let updateMarks = (projectUuid, uuids) => api.collisionRules.markRules(projectUuid, uuids)
let debouncedUpdateMarks = debounce(updateMarks, 700)

function filterTree(tree) {
  tree.forEach(node => {
    if (node.nodes) {
      node.nodes = filterTree(node.nodes)
    }
  })

  return tree.filter(node => (node.isRule && !node.disabled) || (!node.disabled && node.nodes && node.nodes?.length > 0))
}

function getNodeFolderPath(tree, folderUuid){
  let arr = []
  for(let node of tree){
    if(node.isFolder){
      if(node.uuid === folderUuid){
        arr.push(node)
        break
      } else {
        let folder = getNodeFolderPath(node.nodes, folderUuid)
        if(folder.length) {
          arr.push(node, ...folder)
          break
        }
      }
    }
  }
  return arr
}

const attributeFinder = [
  "com.pirsbim.collision.rule.AttributeClashRule",
  "com.pirsbim.collision.rule.IFCValidateRule"
]

const getDefaultState = () => {
  return {
    ruleTree: [],
    markedRules: [],
    selectedRules: [],
    currentProjectUuid: null,

    selectedAttrRules: [],

    openedFolder: {
      rules: [],
      founder: [],
      attribute: [],
    },

    isTreeLoading: false,
    resettingSelection: false,
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  modules: {
    details, global
  },

  state,

  getters: {
    // общее дерево правил
    preparedRuleTree: ({ ruleTree }) => ruleTree.map(RuleTreeMapper), 
    rulesFlatList: ({ ruleTree }) => allRules(ruleTree),
    folderFlatList: ({ ruleTree }) => flatlist(ruleTree),
    countFolder: ({ ruleTree }) => {
      let tree = flatlist(ruleTree)
      let count = 0
      tree.forEach(el => {
        if (el.children?.length > 0 || el.rules?.length > 0)
          count++
      })
      return count
    },

    // коллизии
    countActiveFolder: ({ ruleTree }) => flatlistNodes(filterTree(ruleTree.map(rule => ActiveRuleTreeMapper(rule, false)))).length,
    preparedActiveRuleTree: ({ ruleTree }) => filterTree(ruleTree.map(rule => ActiveRuleTreeMapper(rule, false))),

    // атрибуты
    countActiveAttrFolder: ({ ruleTree }) => flatlistNodes(filterTree(ruleTree.map(rule => ActiveRuleTreeMapper(rule, true)))).length,
    preparedActiveAttrRuleTree: ({ ruleTree }) => filterTree(ruleTree.map(rule => ActiveRuleTreeMapper(rule, true))),
    
    // WORK
    markedRules: ({ markedRules }) => markedRules.filter(rule => !rule.nodes),
    markedBaseRules: ({ markedRules }) => markedRules.filter(rule => {
      if (!rule.nodes && !attributeFinder.includes(rule.finder.className))
        return rule
    }),         
    markedAttrRules: ({ markedRules }) => markedRules.filter(rule => {
      if (!rule.nodes && attributeFinder.includes(rule.finder.className))
        return rule
    }),   

    // WORK and CALC
    selectedRules: ({ selectedRules }) => selectedRules,                        
    selectedAttrRules: ({ selectedAttrRules }) => selectedAttrRules,

    isRuleTreeLoading: ({ isTreeLoading }) => isTreeLoading,
  },

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },
    
    setCurrentProjectUuid: (state, id) => { state.currentProjectUuid = id },

    SET_RULE_TREE: (state, list) => { state.ruleTree = list || [] },
    SET_MARKED_RULES: (state, list) => { state.markedRules = list || [] },
    SET_SELECTED_RULES: (state, list) => { state.selectedRules = list || [] },
    SET_MARKED_TO_WORK: (state, list) => { markToWork(state.ruleTree, list) },

    SET_SELECTED_ATTR_RULES: (state, list) => { state.selectedAttrRules = list || [] },

    SET_OPEN_FOLDER_RULES: (state, folder) => { state.openedFolder.rules = folder },
    SET_OPEN_FOLDER_FOUNDER: (state, folder) => { state.openedFolder.founder = folder },
    SET_OPEN_FOLDER_ATTRIBUTE: (state, folder) => { state.openedFolder.attribute = folder },

    setIsLoadingTree: (state, isLoading) => { state.isTreeLoading = isLoading },
    setResettingSelection: (state, flag) => { state.resettingSelection = flag },
  },

  actions: {
    init ({ dispatch, state, commit, rootGetters }) {
      if (state.currentProjectUuid != rootGetters['project/projectUuid']) {
        commit('setCurrentProjectUuid', rootGetters['project/projectUuid'])
        dispatch('details/init')
        dispatch('reloadTree')
      }
    },

    reloadTree ({ commit, state, rootGetters }) {
      let projectUuid = rootGetters['project/projectUuid']
      state.isTreeLoading = true
      return api.collisionRules.tree(projectUuid)
      .then(data => {
        (function sort(tree) {
          tree.sort((a, b) => a.srt - b.srt)
          tree.forEach(node => {
            node.rules.sort((a, b) => a.srt - b.srt)
            sort(node.children)
          })
        })(data)

        commit('SET_RULE_TREE', data)
        state.isTreeLoading = false
        commit("SET_MARKED_RULES", data.reduce(allWorkedRules, []))
        commit("SET_SELECTED_RULES", data.reduce(allSelectedRules, []))
        commit("SET_SELECTED_ATTR_RULES", data.reduce(allAttrSelectedRules, []))
        return true
      })
    },
    
    // Folders
    
    saveFolder ({ dispatch, rootGetters }, { uuid, title, parent }) {
      let project = rootGetters['project/projectUuid']
      let update = () => { dispatch('reloadTree') }
      
      if (uuid) api.collisionRules.editFolder({ uuid, project, title, parent }).then(update)
      else api.collisionRules.addFolder(project, title, parent).then(update)
    },

    deleteFolder ({ dispatch }, folderUuid) {
      api.collisionRules.deleteFolder(folderUuid)
      .then(() => {
        dispatch('reloadTree').then(() => dispatch('axis/tree/fetchTree', null, { root: true }))
      }).catch(error => {
        error.response.data.error_description = i18n.t("section.collision.error.delGroup", localStorage.getItem("locale"))
        AlertService.error(error.response)
      })
    },

    moveFolder({ dispatch }, { source, target, parent}) {
      dispatch
      return api.collisionRules.moveFolder(source, target, parent)
    },

    // Rules

    saveRule({ getters, dispatch, commit, state }, { uuid, title, folder, finder }) {
      let update = () => {
        dispatch('reloadTree').then(() => {
          // let selectedRule = rootGetters['rule/details/rule']
          let rule = getters.folderFlatList.find(group => group.uuid === folder).rules.find(rule => rule.title === title)

          dispatch("rule/details/selectRule", rule, {root: true}).then(()=>{
            let folders = getNodeFolderPath(getters.preparedRuleTree, folder)
            if (folder.length) commit("SET_OPEN_FOLDER_FOUNDER", [...state.openedFolder.founder, ...folders])
          })
          

          // if (rule.uuid !== selectedRule?.uuid) {
          //   dispatch("rule/details/selectRule", rule, {root: true})
          // }
          // else {
          //   selectedRule.title = title
          //   selectedRule.finder = finder
          //   commit("rule/details/SET_RULE", selectedRule, {root: true})
          // }
        })
      }
      
      if (uuid) api.collisionRules.editRule({ uuid, title, folder, finder }).then(async () => {
        const selectedRule = getters.rulesFlatList.find(r => r.uuid === uuid)
        const finderIsChanged = selectedRule.finder.uuid !== finder.uuid

        if (finderIsChanged){
          if (selectedRule.exceptions && selectedRule.exceptions.length > 0) {
            await Promise.all(selectedRule.exceptions.map(exception => {
              return api.collisionRules.removeExceptionRuleCondition(exception.uuid)
            }))
          }
          
          if (selectedRule.sampleB && selectedRule.sampleB.length > 0) {
            await(selectedRule.sampleB.map(exception => {
              return api.collisionRules.removeRuleCondition(exception.uuid)
            }))
          }
        }
        
        update()
      })
      else api.collisionRules.addRule({ title, folder, finder }).then(update)
    },

    deleteRule ({ dispatch, commit, rootGetters }, ruleUuid) {
      let selectedRule = rootGetters['rule/details/rule']
      if(ruleUuid === selectedRule?.uuid) {
        commit('rule/details/SET_RULE', null, {root: true})
      }
      api.collisionRules.deleteRule(ruleUuid)
      .then(() => {
        dispatch('reloadTree').then(() => dispatch('axis/tree/fetchTree', null, { root: true }))
      })
      .catch(error => {
        error.response.data.error_description = i18n.t("section.collision.error.delRule", localStorage.getItem("locale"))
        AlertService.error(error.response)
      })
    },

    moveRule({ dispatch },  { source, target, folder }){
      dispatch
      return api.collisionRules.moveRule(source, target, folder)
    },

    copyRule ({ dispatch }, ruleUuid) {
      api.collisionRules.copyRule(ruleUuid)
      .then(() => {
        dispatch('reloadTree')
      })
    },

    markRules ( { commit, getters, rootGetters, dispatch }, rules ) {
      if (xorBy(getters.markedRules, rules, 'uuid').length > 0) {
        commit('SET_MARKED_RULES', rules)
        commit('SET_MARKED_TO_WORK', rules.map(i => i.uuid))

        let project = rootGetters['project/projectUuid']
        debouncedUpdateMarks(project, rules.map(i => i.uuid))

        let rulesWithoutAttrRules = state.selectedAttrRules.map(({ uuid }) => ({ uuid }));
        let copyRules = [...rules];
        let listSelectRules = copyRules.filter(rule => !rulesWithoutAttrRules.some(r => r.uuid === rule.uuid));
  
        dispatch('selectRules', listSelectRules)
      }
    },

    selectRules ({ commit, getters, rootGetters, dispatch }, rules) {
      if (xorBy(getters.selectedRules, rules, 'uuid').length > 0) {
        let project = rootGetters['project/projectUuid']
        api.collisionSettings.selectRules(project, rules.map(o => o.uuid))
        .then(() => {
          commit('SET_SELECTED_RULES', rules)
          dispatch('axis/tree/fetchTree', null, { root: true })
        })
      }
    },

    selectAttrRules ({ commit, rootGetters }, rules) {
      let project = rootGetters['project/projectUuid']
      api.collisionSettings.selectAttrRules(project, rules.map(o => o.uuid))
      .then(() => {
        commit('SET_SELECTED_ATTR_RULES', rules)
        useAttributeCheckingTableStore().getCollisionAttrRules(rules)
      })
    },

    recalculateSelected ({ getters, rootGetters }) {
      let project = rootGetters['project/projectUuid']
      api.collisionRules.recalculate(project, getters.selectedRules.map(i => i.uuid))
    },

    recalculateAttrSelected ({ getters, rootGetters }) {
      let project = rootGetters['project/projectUuid']
      api.collisionRules.recalculate(project, getters.selectedAttrRules.map(i => i.uuid))
    },

    recalculateAll ({ rootGetters }) {
      let project = rootGetters['project/projectUuid']
      return api.collisionRules.recalculateAll(project)
    },
  }
}