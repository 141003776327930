import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode"
import { Grid } from "./grid"
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry"
import { Component } from "@xeokit/xeokit-sdk"
import { setGridsAndAxesCulledByAabb } from "./grids.utils"
import { createAabb } from "@/plugins/xeokit/plugins/geometry/aabb"

const ORTHO_SCALE_MULTIPLIER = 0.085

/*eslint-disable no-dupe-class-members*/
export class ModelGrids extends Component {
  constructor(plugin, cfg = {}) {
    super(plugin.viewer.scene, cfg)

    this.plugin = plugin
    this.scene = plugin.viewer.scene

    this.uuid = cfg.uuid ?? null
    this.revisionUuid = cfg.modelRevision ?? null
    this.modelUuid = cfg.modelUuid ?? null

    this.grids = {}
    this.aabb = createAabb() // Заполняется в процессе создания
    this.config = {
      font: cfg.font,
      color: cfg.color ?? [0.5, 0.5, 0.5],
      glowThrough: cfg.glowThrough ?? false,
      visible: cfg.visible ?? true,
    }

    this.viewMatrixDebouncerId = null
    this.sectionPlaneUpdatedDebouncerId = null
    this.debounceTimeout = null
    this.dynamicalScaleListenerId = null

    this.#create(cfg.grids)
  }

  #create(grids) {
    this.node = new XeokitNode(this.scene)

    for (const grid of grids) {
      this.grids[grid.uuid] = new Grid(this, Object.assign(grid, this.config))
      this.aabb = this.aabb ? geometry.aabb.expandAABB3(this.aabb, this.grids[grid.uuid].aabb) : this.grids[grid.uuid].aabb
    }
    this.aabbCenter = geometry.aabb.getAabbCenter(this.aabb)

    this.setDebounce(this.plugin.needDebounce)
    this.#setDynamicalScale()
  }

  #setDynamicalScale() {
    let lastDist
    const up = [0, 0, 1]
    const forward = [0, 1, 0]
    const camera = this.scene.camera
    const eye = camera.eye
    this.dynamicalScaleListenerId = camera.on('viewMatrix', () => {
      const dist = geometry.math.distance(eye, this.aabbCenter)
      if (dist != lastDist) {
        const quat = geometry.math.getLookAtCameraQuaternion(camera, up, forward)
        this.setLabelsQuaternion(quat)
        if (camera.projection == "perspective") {
          this.updateLabelsScale() // каждый символ сам ищет свой скейл, так достигается равенство размеров символов в перспективе
        }
        else if (camera.projection == "ortho") {
          const dist_corrected = dist * ORTHO_SCALE_MULTIPLIER
          const scale = [dist_corrected, dist_corrected, dist_corrected]
          this.setLabelsScale(scale) // достаточно расчета дистанции от одной точки.
        }
        
        lastDist = dist
      }
    })
  }

  #computeScale() {
    const modelAabb = this.aabb
    const p1 = [modelAabb[0], modelAabb[1], modelAabb[2]]
    const p2 = [modelAabb[3], modelAabb[4], modelAabb[5]]
    const diagLength = geometry.math.distance(p1, p2)

    let labelScale
    if (diagLength == 1) labelScale = diagLength
    else labelScale = diagLength / 80

    return labelScale
  }

  #debounce() {
    if (this.plugin.projectGridsActive) {
      this.setVisible(false)
      this.debounceTimeout && clearTimeout(this.debounceTimeout)

      this.debounceTimeout = setTimeout(() => {
        this.setVisible(true)
      }, 300)
    }
  }

  setDebounce(value) {
    if (value) {
      this.viewMatrixDebouncerId = this.scene.camera.on('viewMatrix', () => this.#debounce())
      this.sectionPlaneUpdatedDebouncerId = this.scene.on('sectionPlaneUpdated', () => this.#debounce())
    }
    else {
      this.scene.camera.off(this.viewMatrixDebouncerId)
      this.scene.off(this.sectionPlaneUpdatedDebouncerId)
    }
  }

  setCulled(value) {
    this.culled = value
  }

  setCulledByAabb(aabb) {
    setGridsAndAxesCulledByAabb(aabb, this.grids)
  }

  setVisible(value) {
    let flag
    if (this.culled) flag = false
    else flag = value

    Object.keys(this.grids).forEach(uuid => {
      this.grids[uuid].setVisible(flag)
    })
  }

  setLabelsScale(value) {
    Object.keys(this.grids).forEach(uuid => {
      this.grids[uuid].setLabelsScale(value)
    })
  }

  updateLabelsScale() {
    Object.keys(this.grids).forEach(uuid => {
      this.grids[uuid].updateLabelsScale()
    })
  }

  setLabelsQuaternion(quat) {
    Object.keys(this.grids).forEach(uuid => {
      this.grids[uuid].setLabelsQuaternion(quat)
    })
  }

  setGlowThrough(value) {
    Object.keys(this.grids).forEach(uuid => {
      this.grids[uuid].setGlowThrough(value)
    })
  }

  destroy() {
    this.scene.camera.off(this.viewMatrixDebouncerId)
    this.scene.camera.off(this.dynamicalScaleListenerId)
    this.scene.off(this.sectionPlaneUpdatedDebouncerId)
    this.debounceTimeout && clearTimeout(this.debounceTimeout)

    this.node?.destroy()
    this.node = null

    this.grids = null
    this.aabb = null
    this.config = null

    super.destroy()
  }
}