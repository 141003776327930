import { Mesh, VBOGeometry, PhongMaterial, Texture, buildPlaneGeometry, Component } from "@xeokit/xeokit-sdk";
import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode";

/*eslint-disable no-dupe-class-members*/
export class ImagePlane extends Component {

  #owner; #node;
  #texture; #image; #imageHTML; #imageSRC;
  #mesh;
  #imageSizes; #widthSize; #heightSize;
  #dependsOnWidth;
  #opacity;

  constructor(owner, cfg = {}) {
    super(owner.scene, cfg)

    this.#owner = owner

    this.id = cfg.id
    this.#node = null
    this.#texture = null
    this.#mesh = null

    this.#image = cfg.image ?? null
    this.#imageHTML = cfg.imageHTML ?? null
    this.#imageSRC = cfg.imageSRC ?? null

    this.#widthSize = cfg.widthSize ?? 1
    this.#heightSize = cfg.heightSize ?? 1
    this.#imageSizes = []

    this.#opacity = cfg.opacity ?? 0.8

    this.#dependsOnWidth = cfg.dependsOnWidth ?? true

    this.#create()
  }

  get sizes() { return this.#imageSizes }
  get imageSrc() { return this.#imageSRC }
  get widthSize() { return this.#widthSize }
  get heightSize() { return this.#heightSize }

  #create() {
    this.#node = new XeokitNode(this.#owner)
    this.#texture = new Texture(this.#node, {
      // minFilter: 1005,
      // magFilter: 1006,
      // encoding: 3001
    })

    const geometry = new VBOGeometry(this.#node, buildPlaneGeometry({
      xSize: 1,
      zSize: 1,
      xSegments: 1,
      zSegments: 1
    }))

    const material = new PhongMaterial(this.#node, {
      diffuse: [0, 0, 0],
      ambient: [0, 0, 0],
      specular: [0, 0, 0],
      diffuseMap: this.#texture,
      emissiveMap: this.#texture,
      backfaces: true,
      alpha: this.#opacity
    })

    this.#mesh = new Mesh(this.#node, {
      material: material,
      geometry: geometry,
      rotation: [90, 0, 0],
      clippable: false
    })

    this.#node.addChild(this.#mesh, false)
    this.#owner.addChild(this.#node, false)

    if (this.#image) this.loadImage(this.#image)
    if (this.#imageSRC) this.setImageSrc(this.#imageSRC)
    if (this.#imageHTML) this.setImageHtml(this.#imageHTML)
  }

  setOpacity(opacity) {
    this.#mesh.material.alpha = opacity
  }

  setWidthSize(size) {
    this.#dependsOnWidth = true
    this.#widthSize = size
    this.#updatePlaneSizeFromImage()
  }

  setHeightSize(size) {
    this.#dependsOnWidth = false
    this.#heightSize = size
    this.#updatePlaneSizeFromImage()
  }

  loadImage(image) {
    const type = image.substring(11, 14)
    if (type == 'svg') {
      this.setImageHtml(image)
    }
    else if (type == 'png') {
      this.setImageSrc(image)
    }

    return
  }

  // HTML image
  setImageHtml(image) {
    this.#imageHTML = image
    if (this.#imageHTML) {
      this.#imageSizes[0] = image.width
      this.#imageSizes[1] = image.height
      this.#updatePlaneSizeFromImage()
      this.#imageSRC = null
      this.#texture.image = this.#imageHTML
    }
  }

  // PNG or JPEG image
  setImageSrc(src) {
    this.#imageSRC = src
    if (this.#imageSRC) {
      this.#imageHTML = null
      const image = new Image()
      image.onload = () => {
        this.#texture.image = image
        this.#imageSizes[0] = image.width
        this.#imageSizes[1] = image.height
        this.#updatePlaneSizeFromImage()
      }
      image.src = this.#imageSRC;
    }
  }

  #updatePlaneSizeFromImage() {
    const widthSize = this.#widthSize
    const heightSize = this.#heightSize
    const width = this.#imageSizes[0]
    const height = this.#imageSizes[1]
    
    if (this.#dependsOnWidth) {
      if (width > height) {
        const aspect = width / height
        this.#mesh.scale = [widthSize * aspect, 1.0, widthSize]
      } 
      else {
        const aspect = height / width
        this.#mesh.scale = [widthSize, 1.0, widthSize * aspect]
      }
    }
    else {
      if (width > height) {
        const aspect = height / width;
        this.#mesh.scale = [heightSize, 1.0, heightSize * aspect]
      } 
      else {
        const aspect = width / height;
        this.#mesh.scale = [heightSize * aspect, 1.0, heightSize]
      }
    }
  }

  destroy() {
    this.#node?.destroy()
    this.#node = null
    this.#texture?.destroy()
    this.#texture = null
    this.#mesh?.destroy()
    this.#mesh = null

    this.#image =  null
    this.#imageHTML =  null
    this.#imageSRC =  null
  }
}