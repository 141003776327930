import { useImageSectionPlanesStore, useDrawingCroppingStore, useDrawingPageStore } from "@/pinia";
import vuexStore from "@/store";
import { projectService } from "@/_services";
import { createCfgByAnchorPointsData } from "@/plugins/xeokit/imagePlanes/imageSectionPlanesCreator";
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import { useDrawings3DStore } from "@/pinia/drawings3D.store";
import { useActiveToolWindowStore } from "@/stores/viewerTools/activeToolWindow.store";
import { ToolWindow } from "@/stores/viewerTools/activeToolWindow.store";

export const isEditMode = () => useImageSectionPlanesStore().isEditMode
export const isAnchorPointsPicking = () => useImageSectionPlanesStore().isAnchorPointsPicking
export const isAnchorScenePointsAdded = () => useDrawingCroppingStore().isAnchorScenePointsAdded
export const isDrawing3DNormalPicked = () => useDrawingCroppingStore().isNormalOnSceneAdded
export const isDrawingSettingMode = () => useDrawingCroppingStore().isDrawingSettingMode

export function activateAnchorPointsSceneController() {
  XeokitMediator.Drawings3D.activateAnchorPointsSceneController()
  useImageSectionPlanesStore().isAnchorPointsPicking = true
  useDrawingCroppingStore().isDrawingSettingMode = true
}

export function cancelDrawingPlacing() {
  useDrawingCroppingStore().isDrawingSettingMode = false
  useDrawingCroppingStore().isAnchorPointsAdded = false
  useImageSectionPlanesStore().isAnchorPointsPicking = false 

  XeokitMediator.Drawings3D.deactivateAnchorPointsSceneController()
  XeokitMediator.Drawings3D.deactivateNormalPicker()
  XeokitMediator.Drawings3D.clearAnchorPoints()
  XeokitMediator.Drawings3D.clearDrawing3DNormal()
}

export function applySceneAnchorPointsPlacement() {
  useImageSectionPlanesStore().isAnchorPointsPicking = false
  // useImageSectionPlanesStore().isEditMode = true

  XeokitMediator.Drawings3D.deactivateAnchorPointsSceneController()
  XeokitMediator.Drawings3D.deactivateNormalPicker()
  XeokitMediator.Drawings3D.clearAnchorPoints()
  XeokitMediator.Drawings3D.clearDrawing3DNormal()

  createByAnchorPoints()
}

export function clearAnchorPoints() {
  XeokitMediator.Drawings3D.clearAnchorPoints()
}

export function createImageSectionPlane(cfg) {
  const id = XeokitMediator.ImageSectionPlanes.createImageSectionPlane(cfg)
  saveImageSectionPlanesToStore()

  return id
}

export function deactivateAnchorPointsSceneController() {
  XeokitMediator.Drawings3D.deactivateAnchorPointsSceneController()
}

export function clearDrawing3DNormal() {
  XeokitMediator.Drawings3D.clearDrawing3DNormal()
}

export function deactivateDrawing3DNormalPicker() {
  XeokitMediator.Drawings3D.deactivateNormalPicker()
}

export function activateNormalPicker() {
  XeokitMediator.Drawings3D.activateNormalPicker()
}

export function createByAnchorPoints() {
  const config = createCfgByAnchorPointsData({
    sceneAnchorPoints: useDrawingCroppingStore().sceneAnchorPoints,
    drawingAnchorPoints: useDrawingCroppingStore().drawingAnchorPoints,
    drawing3DAnchorPoints: useDrawingCroppingStore().drawing3DAnchorPoints,
    croppingRectangleRelative: useDrawingCroppingStore().croppingRectangleRelative,
    originalHeight: useDrawingPageStore().originalHeight,
    originalWidth: useDrawingPageStore().originalWidth,
    normal: useDrawingCroppingStore().sceneNormal
  })
  config.image = useImageSectionPlanesStore().imageSrc
  
  const id = createImageSectionPlane(config)
  useImageSectionPlanesStore().editImageSectionPlaneId = id
}

export function destroyImageSectionPlane(id) {
  const imageSectionPlane = XeokitMediator.ImageSectionPlanes.imageSectionPlanes[id]
  XeokitMediator.ImageSectionPlanes.removeImageSectionPlanes([imageSectionPlane])
}

export function saveImageSectionPlanesToStore() {
  let planes = XeokitMediator.ImageSectionPlanes.imageSectionPlanes
  const planesToSave = {}
  for (const key in planes) {
    const plane = planes[key]
    planesToSave[key] = {
      id: plane.id,
      title: plane.title ?? 'untitled',
      position: plane.position,
      quaternion: plane.quaternion,
      up: plane.up,
      normal: plane.normal,
      widthSize: plane.widthSize,
      heightSize: plane.heightSize,
      createdTime: plane.createdTime,
      opacity: plane.opacity
    }
  }
  planes = null
  useImageSectionPlanesStore().imageSectionPlanes = planesToSave
}

export function cancelEdit() {
  const id = useImageSectionPlanesStore().editImageSectionPlaneId
  XeokitMediator.Drawings3D.destroyImageSectionPlane(id)
  useDrawingCroppingStore().isDrawingSettingMode = false
  useImageSectionPlanesStore().isEditMode = false
  useImageSectionPlanesStore().editImageSectionPlaneId = null
  useDrawings3DStore().selectedDrawing3DUuid = null
}

export function setEditedImageSettings() {
  const id = useImageSectionPlanesStore().editImageSectionPlaneId
  const plane = XeokitMediator.ImageSectionPlanes.imageSectionPlanes[id]
  useImageSectionPlanesStore().editImageSectionPlaneId = null
  projectService.saveDrawingOnModel({
    project: vuexStore.getters['project/projectUuid'],
    title: useDrawings3DStore().editedDrawing3DTitle,
    image64: plane.imageSrc,
    imageSize: null, // TODO избавиться
    widthSize: plane.widthSize,
    heightSize: plane.heightSize,
    position: Array.from(plane.position),
    quaternion: Array.from(plane.quaternion),
    normal: Array.from(plane.normal),
    opacity: plane.opacity,
    up: Array.from(plane.up),
    uuid: plane.id
  }).then(() => {
    useDrawingCroppingStore().isDrawingSettingMode = false
    useImageSectionPlanesStore().isEditMode = false
    useActiveToolWindowStore().setActiveWindow(ToolWindow.PLUGIN_CUBES)
    useDrawings3DStore().selectedDrawing3DUuid = plane.id
    loadDrawing3DList()
  })
}

export function applyTitle(title) {
  useDrawings3DStore().editedDrawing3DTitle = title
}

export function applyPosition(position) {
  editedPlane().setPosition(position)
}

export function applyRotation(rotation) {
  editedPlane().setRotation(rotation)
}

export function applyWidthSize(widthSize) {
  editedPlane().setWidthSize(widthSize)
}

export function applyHeightSize(heightSize) {
  editedPlane().setHeightSize(heightSize)
}

export function applyOpacity(opacity) {
  const converted = 1 - opacity / 100
  editedPlane().setOpacity(converted)
}

export async function loadDrawing3DList() {
  useDrawings3DStore().drawing3DList = await projectService.getDrawingsOnModelShortByProject(vuexStore.getters['project/projectUuid'])
}

export async function toggleDrawing3D(uuid) {
  if (useDrawings3DStore().selectedDrawing3DUuid) {
    if (useDrawings3DStore().selectedDrawing3DUuid != uuid) {
      useImageSectionPlanesStore().isEditMode = false
      unselectDrawing3D()
      selectDrawing3D(uuid)
    }
    else {
      unselectDrawing3D()
    }
  }
  else selectDrawing3D(uuid)
}

export function editDrawing3D(uuid) {
  const store = useImageSectionPlanesStore()
  if (store.isEditMode) {
    if (store.editImageSectionPlaneId == uuid) {
      unselectDrawing3D()
      store.editImageSectionPlaneId = null
      store.isEditMode = false
    }
    else if (store.editImageSectionPlaneId != uuid) {
      store.isEditMode = false
      unselectDrawing3D()
      selectDrawing3D(uuid).then(() => {
        store.editImageSectionPlaneId = uuid
        store.isEditMode = true
      })
    }
  }
  else if (useDrawings3DStore().selectedDrawing3DUuid) {
    if (useDrawings3DStore().selectedDrawing3DUuid == uuid) {
      store.editImageSectionPlaneId = uuid
      store.isEditMode = true
    }
    else {
      store.isEditMode = false
      unselectDrawing3D()
      selectDrawing3D(uuid).then(() => {
        store.editImageSectionPlaneId = uuid
        store.isEditMode = true
      })
    }
  }
  else {
    selectDrawing3D(uuid).then(() => {
      store.editImageSectionPlaneId = uuid
      store.isEditMode = true
    })
  }
}

export async function selectDrawing3D(uuid) {
  let drawing3D = await projectService.getDrawingOnModelListByUuids(uuid)
  createImageSectionPlane({
    coordinates: drawing3D.coordinates, // TODO: переделать на бэке
    id: drawing3D.uuid,
    image: drawing3D.image64,
    position: drawing3D.position,
    normal: drawing3D.normal,
    up: drawing3D.up,
    quaternion: drawing3D.quaternion,
    heightSize: drawing3D.heightSize,
    widthSize: drawing3D.widthSize,
    opacity: drawing3D.opacity,
    title: drawing3D.title,
    createdTime: drawing3D.createdTime
  })
  drawing3D = null
  useDrawings3DStore().selectedDrawing3DUuid = uuid
}

export function selectDrawing3DForTasks(uuid) {
  if (useDrawings3DStore().selectedDrawing3DUuid) {
    if (useDrawings3DStore().selectedDrawing3DUuid == uuid) return
    else {
      unselectDrawing3D()
      selectDrawing3D(uuid)
    }
  }
  else selectDrawing3D(uuid)
}

export function unselectDrawing3D() {
  const uuid = useDrawings3DStore().selectedDrawing3DUuid
  let selectedDrawing3D = XeokitMediator.ImageSectionPlanes.imageSectionPlanes[uuid]
  XeokitMediator.ImageSectionPlanes.removeImageSectionPlanes([selectedDrawing3D])
  selectedDrawing3D = null
  useDrawings3DStore().selectedDrawing3DUuid = null
}

export async function deleteDrawing3D(uuid) {
  const selectedDrawing3D = XeokitMediator.ImageSectionPlanes.imageSectionPlanes[uuid]
  if (selectedDrawing3D) XeokitMediator.ImageSectionPlanes.removeImageSectionPlanes([selectedDrawing3D])

  await projectService.deleteDrawingOnModel(uuid)
  loadDrawing3DList()

  if (useImageSectionPlanesStore().editImageSectionPlaneId == uuid) {
    useImageSectionPlanesStore().isEditMode = false
    useImageSectionPlanesStore().editImageSectionPlaneId = null
  }

  if (useDrawings3DStore().selectedDrawing3DUuid == uuid) {
    useDrawings3DStore().selectedDrawing3DUuid = null
  }
}

export function editedDrawing3DTitle() {
  const id = useImageSectionPlanesStore().editImageSectionPlaneId
  const drawing = useDrawings3DStore().drawing3DList.find(drawing => drawing.uuid == id)
  if (drawing) {
    useDrawings3DStore().editedDrawing3DTitle = drawing.title
  }
  return useDrawings3DStore().editedDrawing3DTitle
}

export function editedDrawing3DPosition() {
  return editedPlane().position
}

export function editedDrawing3DRotation() {
  return editedPlane().rotation
}

export function editedDrawing3DWidthSize() {
  return editedPlane().widthSize
}

export function editedPlane() {
  const id = useImageSectionPlanesStore().editImageSectionPlaneId
  return XeokitMediator.ImageSectionPlanes.imageSectionPlanes[id]
}

export function getDrawings3DForTasks() {
  const drawing3DUuids = []
  for (const id in XeokitMediator.ImageSectionPlanes.imageSectionPlanes) {
    const exists = useDrawings3DStore().drawing3DList.find(drawing => drawing.uuid == id)
    if (exists) drawing3DUuids.push(id)
  }

  const selectedDrawing3DUuid = useDrawings3DStore().selectedDrawing3DUuid
  return {
    drawing3DUuids: drawing3DUuids,
    selectedDrawing3DUuid: selectedDrawing3DUuid,
  }
}