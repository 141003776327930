export default ({ Factory }) => ({
  loadWormCompounds:(wormNodeUuid) => Factory.worm.node[wormNodeUuid].compounds.get(),

  saveCompound: (compound) => Factory.worm.compound.save.post(compound),
  removeCompound: (uuid) => Factory.worm.compound[uuid].delete(),
  dropCompoundToDefault: (wormUuid) => Factory.worm.node[wormUuid].dropcompoundtodefault.patch(),

  loadHighLigthsGroup: (projectWormUuid,ruleUuid) => {
    let query = `?projectWormUuid=${projectWormUuid}&ruleUuid=${ruleUuid}`
    return Factory.worm.hightlightgroup[query].get()
  },

  nodesforelement: (projectWormUuid,elementUuid) => Factory.worm.projectworm[projectWormUuid].nodesforelement[elementUuid].get(),

  exportWorm: (req) => Factory.worm.export.put(req),

  replaceCompound: (req) => Factory.worm.compound.replace.post(req),
  removeReplaceCompound: (uuid) => Factory.worm.compound[uuid].removereplace.patch(),

  recalculateprojectworm: (uuid) => Factory.worm.recalculateprojectworm[uuid].patch(),

  findElementRules: (uuid) => {
    let query = `?uuid=${uuid}`
    return Factory.worm.findelementrules[query].get()
  },

  fetchLoadedDrawings: (uuid) => Factory.worm.projectworm.node[uuid].loaddrawings.get(),

  exportToGge: (uuid) => Factory.worm.exportgge[uuid].get({ responseType: 'blob' })
})