import { Plugin, math } from '@xeokit/xeokit-sdk'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { ImageSectionPlane } from './reworkImagePlane/imageSectionPlane';

export class ImageSectionPlanesPlugin extends Plugin {

  /**
   * Создать плагин {@link ImageSectionPlanesPlugin}
   */
  constructor() {
    super("ImageSectionPlanesPlugin", XeokitMediator.viewer)
    this._imageSectionPlanes = {}
  }

  /**
   * Получает существующие {@link imageSectionPlane}ы, каждая из которых сопоставляется по своему {@link ImageSectionPlane#id}.
   */
  get imageSectionPlanes() {
    return this._imageSectionPlanes
  }

  /**
   * Создать {@link ImageSectionPlane}
   * 
   * @param {String} params.id
   * 
   * @returns id
   */
  createImageSectionPlane(params = {}) {
    if (this.viewer.scene.components[params.id]) {
      this.error("Viewer scene component with this ID already exists: " + params.id)
      delete params.id;
    }
    let imageSectionPlane = new ImageSectionPlane(this, {
      coordinates: params.coordinates ?? null, // TODO временное решение с бэка для чтения с аннотаций PDF
      id: params.id ?? math.createUUID(),
      image: params.image,
      widthSize: params.widthSize,
      heightSize: params.heightSize,
      quaternion: params.quaternion,
      position: params.position,
      normal: params.normal,
      opacity: params.opacity,
      up: params.up
    })
    this._imageSectionPlanes[imageSectionPlane.id] = imageSectionPlane

    imageSectionPlane.on("destroyed", () => {
      console.log('destroyed')
      delete this._imageSectionPlanes[imageSectionPlane.id]
    });

    this.fire("imageSectionPlane", imageSectionPlane)
    const id = imageSectionPlane.id
    // imageSectionPlane = null
    return id
  }

  /**
   * Удалить указанные ImagePlanes.
   * 
   * @param {Object[]} imageSectionPlanes
   */
  removeImageSectionPlanes(imageSectionPlanes) {
    imageSectionPlanes.forEach(plane => {
      this._imageSectionPlanes[plane.id].destroy()
    })
  }

  removeAll() {
    for (const plane in this._imageSectionPlanes) {
      this._imageSectionPlanes[plane.id].destroy()
    }
  }

  /**
   * Уничтожить текущий {@link ImageSectionPlanesPlugin}.
   * Сначала уничтожает все {@link ImageSectionPlane}.
   */
  destroy() {
    this.removeAll()
    super.destroy()
  }
}