import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry"
import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode"
import { Curve } from "./curve"
import { DashDottedSegment } from "./dashDottedSegment"
import { GridAxisLabel } from "./gridAxisLabel"
// import { createAabb } from "@/plugins/xeokit/plugins/geometry/aabb"

const STATE_INHERIT = true

export class GridAxis {
  constructor(owner, cfg = {}) {
    this.uuid = cfg.uuid ?? null

    this.owner = owner
    this.node = null

    this.curve = cfg.curve ? new Curve(cfg.curve) : null
    this.label = cfg.label ?? '<>'
    this.labelLocation1 = cfg.location1 ?? [0, 0, 0]
    this.labelLocation2 = cfg.location2 ?? [0, 0, 0]

    this.dashDottedCurve = null
    this.textLabel1 = null
    this.textLabel2 = null

    this.matrix = cfg.matrix ?? [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]
    this.color = cfg.color ?? [0.5, 0.5, 0.5]
    this.glowThrough = cfg.glowThrough ?? false
    this.visible = cfg.visible ?? true

    this.revisionAabb = cfg.revisionAabb

    this.p1 = null
    this.p2 = null

    this.aabb = null

    this.p1Gap = null
    this.p2Gap = null

    this.culled = false

    this.font = cfg.font

    this.#create()
  }

  #create() {
    this.node = new XeokitNode(this.owner.node)
    
    const vertices = this.curve.vertices
    const v1 = vertices[0]
    const v2 = vertices[1]
    const v3 = vertices[2]
    const v4 = vertices[3]
    const v5 = vertices[4]
    const v6 = vertices[5]
    
    this.p1 = geometry.math.vec3ApplyMat4(this.matrix, [v1, v2, v3])
    this.p2 = geometry.math.vec3ApplyMat4(this.matrix, [v4, v5, v6])

    this.rayDir = geometry.math.normalizeVec3(geometry.math.subVec3(this.p2, this.p1))
    this.owner.zCoord = this.p1[2]
    this.aabb = geometry.aabb.buildAabbByPointsArray([this.p1, this.p2])
    this.owner.aabb = geometry.aabb.expandAABB3(this.owner.aabb, this.aabb)
    
    this.dashDottedCurve = new DashDottedSegment(this.node, {
      vertices: [this.p1, this.p2],
      color: this.color,
      unitNumber: 10,
      glowThrough: this.glowThrough
    })

    this.textLabel1 = new GridAxisLabel(this.node, {
      font: this.font,
      color: this.color,
      position: this.p1,
      text: this.label
    })

    this.textLabel2 = new GridAxisLabel(this.node, {
      font: this.font,
      color: this.color,
      position: this.p2,
      text: this.label
    })
    
    this.owner.node.addChild(this.node, STATE_INHERIT)

    this.node.selected = false
    this.node.visible = this.visible
    this.node.pickable = false
    this.node.clippable = false
  }

  setVisible(value) {
    let flag
    if (this.culled) flag = false
    else flag = value 

    this.node.visible = flag
  }

  redrawByAabb(aabb) {
    let intersects
    if (geometry.intersection.isLineIntersectsAabb(this.p1, this.rayDir, aabb)) {
      intersects = true
    }
    else intersects = false

    if (intersects) {
      this.setCulled(false)
    }
    else this.setCulled(true)
  }

  setGlowThrough(value) {
    this.dashDottedCurve.setGlowThrough(value)
  }

  setLabelsScale(value) {
    this.textLabel1.setScale(value)
    this.textLabel2.setScale(value)
  }

  setLabelsQuaternion(value) {
    this.textLabel1.setQuaternion(value)
    this.textLabel2.setQuaternion(value)
  }

  updateLabelsQuaternion() {
    this.textLabel1.updateQuaternion()
    this.textLabel2.updateQuaternion()
  }

  updateLabelsScale() {
    this.textLabel1.updateScale()
    this.textLabel2.updateScale()
  }

  setCulled(value) {
    this.culled = value
  }
}