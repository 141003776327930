import { defineStore } from 'pinia'
import { drawingService } from '@/_services'
import { Drawing } from "@/assets/model/drawing/Drawing"
import { DrawingPage } from "@/assets/model/drawing/DrawingPage"
import { DrawingComment } from '@/assets/model/drawing/DrawingComment'
import { api } from '@/api'
import _ from "lodash"
import vuexStore from '@/store'

export const useDrawingsStore = defineStore('drawings', { // TODO Разбить на несколько стор
  
  state: () => {
    return {
			previewImages: [],
			modelsWithGroups: [],
			drawings: [],
			drawingsPages: [],
			flagCommentToImport: false,
			commentImportMode: false,
			commentsToImport: [],
			commentGrabbing: null,
			ignoreConsoleWarn: 0,
			pageIsFittingSize: false,
			selectedDrawingPage: null,
      scaleDrawing: 100,
			drawingsIsLoading: false,
			flagDrawingsPages: false,
			searchFilter: null,
			pageForRecovery: null,
      drawingsPanel: null,
      task: null,
      loadCommentsInPage: [],

			originalPageSize: {
        width: null,
        height: null
      },
			pageIsLoading: false,
			currentPseudoPanelSizes: {
        width: null,
        height: null
      },

      imageSize: {
        width: null, 
        height: null
      },

			contextMenuPosition: null,
			show: {
        contextMenu: false
      },
			commentMode: false,
			drawingIsGrabbing: false,
			preparedSrc: null,

      newCommentForm: {},
      showCommentTaskBinding: false,
      tasksIsLoading: false,
      dialogVisible: false,

      isCroppingModeActive: false, 
      changeCommentDialog: {
        show: false,
        comment: new DrawingComment(),
      }, 

      addNewDrawingDialog: {
        show: false,
        drawing: {},
        firstLoadDrawing: false,
      }, 
      editDrawingDialog: {
        show: false, 
        drawing: {},
      },
      sidePanelVisible: false,
    }
  },

  getters: {
    getSearchValidPages() {
      const validModels = []
      this.modelsWithGroups.map(model => {
        const validModel = {
          name: model.name,
          visible: true,
          uuid: model.uuid,
          groups: []
        }
        model.groups.map(group => {
          const validPages = group.pages.filter(page => page.name?.toLowerCase().includes(this.searchFilter.toLowerCase()) || page.number == this.searchFilter)
          if (validPages.length > 0 || group.drawing.name.toLowerCase().includes(this.searchFilter.toLowerCase())){
            let validGroup = {
              drawing: null,
              pages: []
            }
            if (validPages.length > 0){
              validGroup = {
                drawing: group.drawing,
                pages: validPages
              }
            }
            else {
              validGroup = {
                drawing: group.drawing,
                pages: group.pages
              }
            }
            validModel.groups.push(validGroup)
          }
        })
        validModels.push(validModel)
      })
      return validModels
    },

  },

  actions: {
    setCroppingModeActive(value) {
      this.isCroppingModeActive = value
    },

    setFlagComment(flagCommentToImport) {
      this.flagCommentToImport = flagCommentToImport
    },

		setPageIsLoading(value) {
			this.pageIsLoading = value
		},

    setCommentGrabbing(grabbing) {
      this.commentGrabbing = grabbing
    },

    setScaleDrawing(value) {
			if (value >= 25 && value <= 200) this.scaleDrawing = value
    },

		setDrawingsPages(drawingsPages) {
      this.drawingsPages = drawingsPages
    },

		setPageForRecovery(page) {
      this.pageForRecovery = page
    },

    setDrawingsPanel(panel) {
      this.drawingsPanel = panel
    },

		setFlagDrawingsPages(flag) {
      this.flagDrawingsPages = flag
    },

		setModelsWithGroups(models) {
      this.modelsWithGroups = models
    },

		addPreviewImage(uuid, url) {
      this.previewImages.push({ uuid, url })
    },

    showNewDrawingDialog() {
      this.addNewDrawingDialog.drawing = {
        model: {
          name : ""
        },
        name: "",
        file: {
          name: ""
        }
      }
      this.addNewDrawingDialog.show = true
    },

		getPagesByDrawingVersion(uuid) {
      //изначально заполненный, потом пустой, потом опять полный
      return this.drawingsPages.filter(page => page.version.uuid == uuid).sort((page, b) => page.number - b.number)
    },

		getDrawingPagePreview(pageUUID) {
      return drawingService.getDrawingPreview(pageUUID).then(preview => {
        return preview
      })
    },

    getDrawingPage(pageUUID) {
      return drawingService.loadDrawingPage(pageUUID).then(pageSvg => {
        return pageSvg
      })
    },

		includePages(pages) {
      drawingService.includePages(pages, vuexStore.getters['project/projectUuid'], pages[0].drawing.uuid).then(includingPages => {
        const projectUuid = this.drawingsPages.find(page => page.uuid == includingPages[0].uuid)?.drawing.projectUuid
        if (!projectUuid) this.loadDrawings(projectUuid)
      }) 
    },

    changeDrawing(drawing) {
      drawing.currentVersion = drawing.currentVersion.uuid
      return drawingService.changeDrawing(drawing.uuid, drawing.name, drawing.model.uuid).then(changedDrawing => {
        this.modelsWithGroups.map(model => {
          let existingModelIndex = model.groups.find(group => group.drawing.uuid == changedDrawing.uuid)
          if(existingModelIndex) {
            model.groups.splice(existingModelIndex, 1)
          }
        })
        this.loadDrawings(changedDrawing.projectUuid)
      })
    },

    archiveDrawing(drawing) {
      drawingService.archiveDrawing(drawing.uuid).then(() =>{
        let existingModel = this.modelsWithGroups.find(model => model.uuid == drawing.model.uuid)
        let existingGroupIndex = existingModel.groups.find(group => group.drawing.uuid == drawing.uuid)
        existingModel.groups.splice(existingGroupIndex, 1)
        this.loadDrawings(drawing.projectUuid)
      })
    },

    downloadVersion(version) {
      return drawingService.downloadVersion(version.uuid).then(version => {
        return version
      })
    },

    addCommentToImportComments(comment) {
      const existingComment = this.commentsToImport.find(comm => comm.uuid == comment.uuid)
      if (!existingComment) {
        this.commentsToImport.push(comment)
      }
    },

    getDrawingCommentAttachment(comment) {
      return drawingService.getCommentAttachment(comment).then(response => this.task = response)
    },
    
    deleteComment(comment) {
      return api.tasks.deleteAttachment(comment.uuid).then(() => {
        vuexStore.dispatch('task/loadTasks', {}, { root: true })
        this.selectedDrawingPage.attachments = this.selectedDrawingPage.attachments.filter(attach => comment.uuid != attach.uuid)
        this.loadCommentsInPage = this.loadCommentsInPage.filter(comm => comm.uuid != comment.uuid)
      })
    },

    editDrawingComment(comment) {
      return drawingService.editComment(comment).then(editing => {
        if (editing.task) {
          vuexStore.dispatch('task/loadTasks', {}, { root: true })
        }

        editing = new DrawingComment(editing)
        const selectedPage = this.drawingsPages.find(page => page.uuid == editing.page)

        const index = selectedPage?.attachments.findIndex(attach => attach.uuid == editing.uuid)
        if (index > -1) {
          selectedPage.attachments[index].text = editing.text
          this.selectedDrawingPage.attachments[index].task = vuexStore.state.task.tasks.find(task => task.uuid == editing.task)
        }
      })
    },

    newComment(comment) {
      return drawingService.addNewComment(comment).then(comment => {
        comment = new DrawingComment(comment)
        this.selectedDrawingPage.attachments.push(comment)
        if (comment.task) {
          this.dialogVisible = false
          vuexStore.dispatch('task/loadTasks', {}, {root: true})
        }
      })
    },

		renamePage(page) {
      return drawingService.renamePage(page).then(renamingPage =>{
        this.drawingsPages.find(page => page.uuid == renamingPage.uuid).name = renamingPage.name
      })
    },

    setCommentsToImport(comments) {
      this.commentsToImport = comments
    },

		setCommentImportMode(mode) {
      if (!mode) {
        this.commentsToImport = []
      }
      this.commentImportMode = mode
    },

    setSearchFilter(filter) {
      this.searchFilter = filter
    },

		getAllVersionsPagesByPageUuid(pageUuid) {
      return drawingService.getVersionPagesByPageUuid(pageUuid).then(pages => {
        const drawing = this.drawings.find(drawing => drawing.uuid == pages[0].drawingVersion.drawing.uuid)
        return pages.map(page => {
          page.version = page.drawingVersion
          page.drawing = drawing
          page = new DrawingPage(page)
          this.drawingsPages.push(page)
          return page
        })
      })
    },

		addNewDrawing(drawing) {
      return drawingService.addNewDrawing(drawing.name, drawing.model.uuid).then(drawing => {
        this.ignoreConsoleWarn
        return drawing
      })
    },

    getCloudDrawingInfo(infoHashes) {
      return drawingService.getCloudDrawingInfo(infoHashes).then(fileInfo => {
        return fileInfo
      })
    },

		importCommentToNewPage(newPage, comments){
      if (newPage === comments[0].page) {
        this.setCommentImportMode(false)
        return 
      }
      else if (this.flagCommentToImport){
        return drawingService.importCommentToNewPage(comments[0].page, newPage.uuid).then(() => {
          const oldPage = this.drawingsPages.find(page => page.uuid == comments[0].page)
          oldPage.attachments.splice(0, comments.length)
          for (const comment of comments){
            comment.page = newPage.uuid
            newPage.attachments.push(comment)
          }
          this.setCommentImportMode(false)
        })
      }
      else {
        return drawingService.importComment(comments[0].page, newPage.uuid, comments[0].uuid).then(() => {
          const oldPage = this.drawingsPages.find(page => page.uuid == comments[0].page)
          oldPage.attachments.splice(oldPage.attachments.findIndex(attach => attach.uuid == comments[0].uuid), 1)
          comments[0].page = newPage.uuid
          newPage.attachments.push(comments[0])
          this.setCommentImportMode(false)
        })
      }
    },

		loadPage(pageUuid) {
      this.commentsToNewPage = this.selectedDrawingPage?.uuid
      //страница на которую надо перенести комментарии
      return drawingService.loadDrawingPage(pageUuid).then(pageSource => {
        if (this.selectedDrawingPage.attachments) { 
					this.loadCommentsInPage = this.selectedDrawingPage.attachments.concat(this.commentsToImport) 
				}
        else this.loadCommentsInPage = this.commentsToImport

        return pageSource
      })
    },

		loadDrawings(projectUuid) {
      this.drawingsIsLoading = true
      return drawingService.getAllDrawings(projectUuid).then(data => {
        this.drawingsIsLoading = false
        this.setModelsWithGroups(getModelsWithGroups(vuexStore.getters['project/flatlist'], this.modelsWithGroups))
        getUniqModelsWithDrawings(data)
        if (!this.flagDrawingsPages) this.drawingsPages = []
        this.flagDrawingsPages = false

        data = data.filter(drawing => drawing.archived == false)
        data.map((drawing) => {
          if (drawing.currentVersion) {
            drawingService.getAllVersions(drawing).then(versions => {
              const currentVersionIndex = versions.findIndex(version => version.uuid == drawing.currentVersion)
              const currentVersion = versions.splice(currentVersionIndex, 1)
              versions = versions.sort((version, b) => version.versionNum - b.versionNum)
              versions.unshift(currentVersion[0])
              drawing.currentVersion = versions.find(version => version.uuid == drawing.currentVersion)
              drawing.versions = versions
              drawing = new Drawing(drawing)
              this.drawings.push(drawing)
              const groupFromDrawing = this.getGroupFromDrawing(drawing, this) // TODO: ПЕРЕДЕЛАТЬ
    
              this.addGroupToModelsWithGroups(groupFromDrawing)
            })
          }
        })
      })
    },

		addGroupToModelsWithGroups(group) {
      const currentModel = this.modelsWithGroups.find(model => model.uuid == group.drawing.model.uuid)
      if (currentModel){
        if (currentModel.visible) {
          group.getPages(group.drawing.currentVersion)
        }
        currentModel.groups.push(group)
      }
    },

		getGroupFromDrawing(drawing, state) {
			const group = {
				drawing: drawing,
				pages: [],
		
				getPages(version) {
					return drawingService.getPagesByVersion(version.uuid).then(pages => {
						pages = pages.map(page => {
							page.version = page.drawingVersion
							page.drawing = this.drawing
							page = new DrawingPage(page)
							
							if (!state.drawingsPages.find(existingPage => existingPage.uuid == page.uuid)) {
								state.drawingsPages.push(page)
							}
							
							return page
						})
						this.pages = pages.sort((a, b) => a.number - b.number)
					})
				}
			}
			return group
		},
  }
})

function getModelsWithGroups(models, existingModels) {
  const modelsWithGroups = []
  models.map(model => {
    const existingModel = existingModels.find(existingModel => existingModel.uuid == model.uuid)
    const modelWithGroup = {
      uuid: model.uuid,
      name: model.name,
      groups: [],
      visible: existingModel ? existingModel.visible : false
    }
    modelsWithGroups.push(modelWithGroup)
  })
  return modelsWithGroups
}

function getUniqModelsWithDrawings(drawings) {
  return _.uniqBy(drawings.map(drawing => drawing.model), 'uuid')
}