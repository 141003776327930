import { projectService } from "@/_services"
import { defineStore } from "pinia"
import store from "@/store"

const UNGROUPABLE_LONGNAME = 'UNGROUPABLE'

const FLOORS_STATE = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
}

class FloorsStateCache {
  constructor() {
    this.floorsCached = []
  }

  get floors() { return this.floorsCached }
  set floors(value) { this.floorsCached = value }

  reset() { this.floorsCached = [] }
}

const floorsStateCache = new FloorsStateCache

const useSectionCubeStore = defineStore('sectionCube', {
  state: () => ({
    sectionCubes: [],
    selectedFloor: null,

    visible: false,
    active: false,
    edited: false,

    FLOORS_STATE: FLOORS_STATE.INITIAL,
  }),

  actions: {
    setActive(active) {
      this.active = active
    },

    setVisible(visible) {
      this.visible = visible
    },

    createSectionCube(sectionCube) {
      sectionCube.id ||= this.sectionCubes.length + 1
      this.sectionCubes.push(sectionCube)
    },

    deleteSectionCube(id) {
      this.sectionCubes = this.sectionCubes.filter(sectionCube => sectionCube.id !== id)
    },

    patchSectionCube(data) {
      const idx = this.sectionCubes.findIndex(sectionCube => sectionCube.id === data.id)
      if (idx < 0) return

      const patched = Object.assign(this.sectionCubes[idx], data)
      return patched
    },

    async loadFloors() {
      const projectUuid = store.getters['project/projectUuid']
      this.FLOORS_STATE = FLOORS_STATE.LOADING
      const data = await projectService.loadElementTree(projectUuid, 4)
      floorsStateCache.floors = data.filter(floor => floor.longName !== UNGROUPABLE_LONGNAME)
      this.FLOORS_STATE = FLOORS_STATE.LOADED
    },

    setSelectedFloor(floorUuid) {
      this.selectedFloor = floorUuid
    },

    setSectionCubeEdited(edited) {
      this.edited = edited
    },

    reset() {
      this.$reset()
      floorsStateCache.reset()
    }
  },

  getters: {
    isFloorsLoading() {
      return this.FLOORS_STATE === FLOORS_STATE.LOADING
    },

    floors() { return floorsStateCache.floors }
  }
})

export { useSectionCubeStore }