import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode";
import { ImagePlane } from "./imagePlane";
import { SectionPlane } from './sectionPlane/sectionPlane'
import { Component } from "@xeokit/xeokit-sdk";
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry";

const DEFAULT_NORMAL = [0, 0, 1]
/*eslint-disable no-dupe-class-members*/
export class ImageSectionPlane extends Component {

  #scene; #node; 
  #imagePlane; #sectionPlane; 
  #scaleControl; #positionControl; #rotationControl; #positionRotationControl;
  #coordinates;
  #quaternion; #position;
  #widthSize; #heightSize;
  #up; #normal;
  #image; #opacity;

  constructor(plugin, cfg = {}) {
    super(plugin.viewer.scene, cfg)

    this.plugin = plugin;
    this.id = cfg.id;

    this.#scene = plugin.viewer.scene
    this.#node = null
    
    this.#imagePlane = null
    this.#sectionPlane = null
    this.#scaleControl = null
    this.#positionControl = null
    this.#rotationControl = null
    this.#positionRotationControl = null
    
    this.#coordinates = cfg.coordinates
    this.#position = cfg.position ?? [0, 0, 0]
    this.#quaternion = cfg.quaternion ?? [0, 0, 0, 0]
    this.#widthSize = cfg.widthSize ?? 1 // used like scale
    this.#heightSize = cfg.heightSize ?? 1

    this.#normal = cfg.normal ?? [0, 0, 1]
    this.#up = cfg.up ?? [0, 0, 0]

    this.#image = cfg.image ?? null
    this.#opacity = cfg.opacity ?? 0.8
  
    this.#create()
  }

  get imageSrc() { return this.#image }
  get position() { return this.#position }
  get quaternion() { return this.#quaternion }
  get normal() { return this.#sectionPlane.normal }
  get widthSize() { return this.#widthSize }
  get heightSize() { return this.#heightSize }
  get rotation() { return geometry.math.quaternionToEuler(this.#quaternion, "XYZ") }
  get opacity() { return this.#opacity }
  get up() { return this.#up }

  #create() {
    this.#node = new XeokitNode(this.#scene)

    // if ( // TODO временное решение для чтения аннотаций с PDF
    //   this.#coordinates &&
    //   this.#position.every((value => value == 0)) &&
    //   this.#quaternion.every((value => value == 0))
    // ) {
    //   this.#computePosition()
    //   this.#computeNormal()
    //   this.#computeUp()
    //   this.#computeSizes()
    // }
    
    this.#imagePlane = new ImagePlane(this.#node, { 
      image: this.#image,
      widthSize: this.#widthSize,
      heightSize: this.#heightSize,
      opacity: this.#opacity,
      // Если когда-либо понадобится масштаб по ширине
      dependsOnWidth: false // this.#widthSize ? true : false
    })

    this.#sectionPlane = new SectionPlane(this.#node, { 
      position: [0, 0, 0], 
      normal: this.#normal
    })

    if (this.#quaternion.every((value => value == 0))) this.#computeQuaternion()
    this.setQuaternion(this.#quaternion)
    this.setPosition(this.#position)
  }
   
  #computeQuaternion() {
    const baseUp = [0, 1, 0]
    const baseNormal = [0, 0, 1]

    const normalQuat = geometry.math.quaternionByTwoVectors(baseNormal, this.#normal)
    const rotatedBaseUp = geometry.math.vec3ApplyQuaternion(normalQuat, baseUp)
    const upQuat = geometry.math.quaternionByTwoVectors(rotatedBaseUp, this.#up)
    const quaternion = geometry.math.mulQuaternions(normalQuat, upQuat)

    this.#quaternion = quaternion
  }

  // #computePosition() {
  //   const lb = [this.#coordinates.x / 1000, this.#coordinates.y / 1000, this.#coordinates.z / 1000]
  //   let lt
  //   let rb
  //   if (this.#coordinates.x2) {
  //     lt = [this.#coordinates.x2 / 1000, this.#coordinates.y2 / 1000, this.#coordinates.z2 / 1000]
  //     rb = [this.#coordinates.x3 / 1000, this.#coordinates.y3 / 1000, this.#coordinates.z3 / 1000]
  //   }
  //   else {
  //     lt = [lb[0], lb[1] + 1, lb[2]]
  //     rb = [lb[0] + 1, lb[1], lb[2]]
  //   }

  //   const diag_dist = geometry.math.distance(rb, lt)
  //   const diag_vec = geometry.math.subVec3(rb, lt)
  //   const diag_vecNorm = geometry.math.normalizeVec3(diag_vec)
  //   const diag_half = geometry.math.mulVec3Scalar(diag_vecNorm, diag_dist / 2)
  //   const diag_center = geometry.math.addVec3(lt, diag_half)

  //   this.#position = diag_center
  // }

  // #computeNormal() {
  //   const p2 = [this.#coordinates.x / 1000, this.#coordinates.y / 1000, this.#coordinates.z / 1000]
  //   let p1
  //   let p3
  //   if (this.#coordinates.x2 / 1000) {
  //     p1 = [this.#coordinates.x2 / 1000, this.#coordinates.y2 / 1000, this.#coordinates.z2 / 1000]
  //     p3 = [this.#coordinates.x3 / 1000, this.#coordinates.y3 / 1000, this.#coordinates.z3 / 1000]
  //   }
  //   else {
  //     p1 = [p2[0], p2[1] + 1, p2[2]]
  //     p3 = [p2[0] + 1, p2[1], p2[2]]
  //   }

  //   const firstSideVec = geometry.math.subVec3(p1, p2) // вычесть из вектора p2 вектор p1
  //   const secondSideVec = geometry.math.subVec3(p1, p3) // вычесть из вектора p3 вектор p1
  //   const dir = geometry.math.crossVec3(firstSideVec, secondSideVec) // найти вектор вращения для этой пары векторов
  //   const normal = geometry.math.normalizeVec3(dir) // нормализовать

  //   this.#normal = normal
  // }

  // #computeUp() {
  //   const lb = [this.#coordinates.x / 1000, this.#coordinates.y / 1000, this.#coordinates.z / 1000]
  //   let lt
  //   if (this.#coordinates.x2 / 1000) {
  //     lt = [this.#coordinates.x2 / 1000, this.#coordinates.y2 / 1000, this.#coordinates.z2 / 1000]
  //   }
  //   else {
  //     lt = [lb[0], lb[1] + 1, lb[2]]
  //   }
    

  //   const up = geometry.math.subVec3(lt, lb)
  //   const normalizedUp = geometry.math.normalizeVec3(up)

  //   this.#up = normalizedUp
  // }

  // #computeSizes() {
  //   const lb = [this.#coordinates.x / 1000, this.#coordinates.y / 1000, this.#coordinates.z / 1000]
  //   let lt
  //   let rb
  //   if (this.#coordinates.x2) {
  //     lt = [this.#coordinates.x2 / 1000, this.#coordinates.y2 / 1000, this.#coordinates.z2 / 1000]
  //     rb = [this.#coordinates.x3 / 1000, this.#coordinates.y3 / 1000, this.#coordinates.z3 / 1000]
  //   }
  //   else {
  //     lt = [lb[0], lb[1] + 1, lb[2]]
  //     rb = [lb[0] + 1, lb[1], lb[2]]
  //   }

  //   const distHeight = geometry.math.distance(lb, lt)
  //   const distWidth = geometry.math.distance(lb, rb)

  //   this.#heightSize = distHeight
  //   this.#widthSize = distWidth
  // }

  setOpacity(opacity) {
    this.#opacity = opacity
    this.#imagePlane.setOpacity(opacity)
  }

  setWidthSize(size) {
    this.#widthSize = size
    this.#imagePlane.setWidthSize(size)
  }

  setHeightSize(size) {
    this.#heightSize = size
    this.#imagePlane.setHeightSize(size)
  }

  setRotation(euler) {
    const quaternion = geometry.math.eulerToQuaternion(euler, "XYZ")
    this.setQuaternion(quaternion)
  }

  setQuaternion(quaternion) {
    const baseUp = [0, 1, 0]
    const up = geometry.math.vec3ApplyQuaternion(quaternion, baseUp)
    this.#up = up
    this.#quaternion = quaternion
    this.#node.quaternion = quaternion
    this.#sectionPlane.setDir(geometry.math.vec3ApplyQuaternion(quaternion, DEFAULT_NORMAL))
  }

  setPosition(position) {
    this.#position = position
    this.#node.position = position
    this.#sectionPlane.setPos(position)
  }

  destroy() {
    this.#sectionPlane?.destroy()
    this.#sectionPlane = null
    this.#imagePlane?.destroy()
    this.#imagePlane = null
    this.#node?.destroy()
    this.#node = null

    try { super.destroy() } 
    catch (error) { error; console.log("|| BAD IMAGE_SECTION_PLANE DESTROY ||") }
  }
}