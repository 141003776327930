import { ShapeGeometry } from "@/plugins/threeJs/fork/src/geometries/shapeGeometry"
import { VBOGeometry, PhongMaterial, Mesh } from "@xeokit/xeokit-sdk"
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry"

const PERSPECTIVE_SCALE_MULTIPLIER = 0.1
const STATE_INHERIT = true

export class GridAxisLabel {
  constructor(owner, cfg = {}) {
    this.owner = owner
    this.camera = this.owner.scene.camera

    this.font = cfg.font ?? null
    this.text = cfg.text ?? '<>'
    this.position = cfg.position ?? [0, 0, 0]
    this.scale = 0.2
    this.color = cfg.color ?? [1, 1, 1]
    this.mesh = null

    this.#create()
  }

  #create() {
    const shapes = this.font.generateShapes(this.text, this.scale)
    const shapeGeometry = new ShapeGeometry(shapes, 1) 
    
    const normals = Array.from(shapeGeometry.attributes.normal.array)
    const positions = Array.from(shapeGeometry.attributes.position.array)
    const indices = Array.from(shapeGeometry.index.array)

    const xktGeometry = {
      primitive: "triangles",
      positions: positions,
      indices: indices,
      normals: normals,
      compressGeometry: true,
    }
    
    const labelGeometry = new VBOGeometry(this.owner, xktGeometry)

    const color = this.color

    const material = new PhongMaterial(this.owner, {
      emissive: color,
      specular: color,
      diffuse: [0, 0, 0],
      ambient: color,
      alpha: 1
    })

    this.mesh = new Mesh(this.owner, {
      geometry: labelGeometry,
      material: material,
      position: [0, 0, 0],
      origin: this.position,
      clippable: false,
    })
    
    this.owner.addChild(this.mesh, STATE_INHERIT)
  }
  
  setScale(value) {
    this.mesh.scale = value
  }

  setQuaternion(value) {
    this.mesh.quaternion = value
  }

  updateScale() {
    const eye = this.camera.eye
    const dist = geometry.math.distance(eye, this.position) * PERSPECTIVE_SCALE_MULTIPLIER
    const scale = [dist, dist, dist]
    this.mesh.scale = scale
  }
}